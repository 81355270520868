import React from "react";
import LoadingIcon from "../../../assets/svg/Loading.svg";
import "./Loading.less";

interface Props {
  className?: string;
  size?: number;
  style?: { [key: string]: string | number }; // ToDo
}

const Loading = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    const classNames = ["loading"];
    if (props.className) {
      classNames.push(props.className);
    }

    const size = props.size || 24;

    return (
      <span
        className={classNames.join(" ")}
        style={{ ...props.style, height: size }}
      >
        <LoadingIcon width={size} height={size} />
      </span>
    );
  }
);

export default Loading;
