import { navigate } from "gatsby";

export const navigateToLocalePage = (locale: string, pathname: string) => {
  switch (locale) {
    case "de":
    case "de-de":
    case "de-at":
    case "de-ch":
      navigate(`/de${pathname}`);
      break;
    default:
      navigate(`/en${pathname}`);
      break;
  }
};
